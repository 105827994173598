<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="">
                    <div class="">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-md-12 card card-custom card-stretch mb-10">
                                    <h3>1. Live video {{ option_on == 1 ? "(Đang chọn)" : "" }} <v-btn
                                                        class="ma-2"
                                                        :loading="loading"
                                                        color="success"
                                                        @click="choose(1)"
                                                    >
                                                        Chọn Live Video
                                                    </v-btn></h3>
                                    <table :style="{width: '100%'}">
                                        <tr>
                                            <td>Tên:</td>
                                            <td>
                                                <v-text-field
                                                    v-model="live_name"
                                                    class="required"
                                                    label="Tên"
                                                    required
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>URL:</td>
                                            <td>
                                                <v-text-field
                                                    v-model="live_url"
                                                    class="required"
                                                    label="URL"
                                                    required
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Mô tả:
                                            </td>
                                            <td>
                                                <v-textarea
                                                    v-model="live_description"
                                                    class=""
                                                    label="Mô tả"
                                                    required
                                                ></v-textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Trạng thái:</td>
                                            <td>
                                                <v-text-field
                                                    v-model="live_status"
                                                    class=""
                                                    label="Trạng thái"
                                                    type="number"
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                    
                                                    <v-btn
                                                        class="ma-2 float-right"
                                                        :loading="loading"
                                                        @click="update_data()"
                                                    >
                                                        Cập Nhật
                                                    </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </div>


                                <div class="col-md-12 card card-custom card-stretch mb-10">
                                    <input type="file" ref="upload" @change="upload_server" style="display:none;">
                                    <h3>2. Static Screen {{ option_on == 2 ? "(Đang chọn)" : "" }}<v-btn
                                                        class="ma-2"
                                                        :loading="loading"
                                                        color="success"
                                                        @click="choose(2)"
                                                    >
                                                        Chọn Static Screen
                                                    </v-btn></h3>
                                    <table :style="{width: '100%'}">
                                        <tr>
                                            <td>Hình Ảnh:</td>
                                            <td>
                                                <div v-if="!static_screen"><small>Sử dụng ảnh tỷ lệ 3:4</small></div>
                                                <template v-if="!static_screen">
                                                    <v-btn color="info" @click="browse_file"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Tải ảnh lên...</v-btn>
                                                </template>
                                                <template v-if="static_screen">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <img :src="static_screen" style="max-width: 200px" width="100%" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <v-btn class="ma-2 float-left" color="error" @click="delete_images">Xoá ảnh</v-btn>
                                                            <!-- <v-btn class="ma-2 float-left" color="info" @click="browse_file">Thêm ảnh</v-btn> -->
                                                        </div>
                                                    </div>
                                                </template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tiêu đề:</td>
                                            <td>
                                                <v-text-field
                                                    v-model="static_title"
                                                    class="required"
                                                    label="Tiêu đề"
                                                    required
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Link Share:
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="static_share_link"
                                                    class=""
                                                    label="Link Share"
                                                    required
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                    
                                                    <v-btn
                                                        class="ma-2 float-right"
                                                        :loading="loading"
                                                        @click="update_data()"
                                                    >
                                                        Cập Nhật
                                                    </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="col-md-12 card card-custom card-stretch mb-10">
                                    <h3>3. SlideShow {{ option_on == 3 ? "(Đang chọn)" : "" }}<v-btn
                                                        class="ma-2"
                                                        :loading="loading"
                                                        color="success"
                                                        @click="choose(3)"
                                                    >
                                                        Chọn SlideShow
                                                    </v-btn></h3>
                                    <v-data-table
                                    :headers="headers"
                                    :items-per-page="100"
                                    :items="slide_show_items"
                                    :loading="loading"
                                    class="elevation-1"
                                    :hide-default-footer="true"
                                    >
                                    <template v-slot:top>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="col-md-4">
                                                <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="primary" text v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm item
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm Item</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="ss_name" label="Tên S/P(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="ss_product_code" label="Mã S/P(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-textarea v-model="ss_description" label="Mô tả" required></v-textarea>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="ss_cost" type="number" label="Giá(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="ss_old_cost" type="number" label="Giá Cũ(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <input type="file" ref="upload_2" @change="upload_server_2" style="display:none;">
                                                                        <div v-if="!ss_url"><small>Sử dụng ảnh tỷ lệ 3:4</small></div>
                                                                            <template v-if="!ss_url">
                                                                                <v-btn color="info" @click="browse_file_2"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Tải ảnh lên...</v-btn>
                                                                            </template>
                                                                            <template v-if="ss_url">
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <img :src="ss_url" style="max-width: 200px" width="100%" />
                                                                                    </div>
                                                                                    <div class="col-md-12">
                                                                                        <v-btn class="ma-2 float-left" color="error" @click="delete_images_2">Xoá ảnh</v-btn>
                                                                                        <!-- <v-btn class="ma-2 float-left" color="info" @click="browse_file">Thêm ảnh</v-btn> -->
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>*Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_ss_item()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </div>
                                        </div>
                                    </template>

                                        <template v-slot:item.Url="{ item }">
                                            <img :src="item.Url" style="width: 50px; height: 50px" />
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <v-icon small @click="confirm_delete(item)" >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    
                                </div>
                                


                                


                                
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Home'
    },
    data() {
        return {
            valid: true,
            id: "",
            live_name: "",
            live_url: "",
            live_description: "",
            live_status: 0,
            option_on: 0,
            static_screen: "",
            static_title: "",
            static_share_link: "",
            slide_show_items: [],

            //for tag modal
            dialog: false,
            ss_cost: 0,
            ss_description: "",
            ss_name: "",
            ss_old_cost: 0,
            ss_product_code: "",
            ss_url: "",

            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", sortable: false, value: 'Url'},
                { text: "Mã S/P", sortable: false, value: 'Product_code'},
                { text: "Tên S/P", sortable: false, value: 'Name'},
                { text: "Giá", sortable: false, value: 'Cost'},
                { text: "Giá Cũ", sortable: false, value: 'Old_cost'},
                { text: "Mô tả", sortable: false, value: 'Description'},
                { text: "Actions", value: 'actions', sortable: false },
            ],

            loading: false,
            name_rules: [],

        };
    },
    watch: {
        homeData(val) {
            this.id = val.Id;
            this.live_name = val.Live_name;
            this.live_url = val.Live_url;
            this.live_description = val.Live_description;
            this.live_status = val.Live_status;
            this.option_on = val.Option_on;
            this.static_screen = val.Static_screen;
            this.static_share_link = val.Static_share_link;
            this.static_title = val.Static_title;
            this.slide_show_items = val.SlideShowItems;
        }
    },
    computed: {
        homeData() {
            return this.$store.getters['home/get_home_data'];
        }
    },
    components: {
        
    },
    methods: {
        update_data() {
            // console.log("this.cate_id", this.cate_id);
            if (this.$refs.form.validate()) {

                // return;
                this.loading = true;
                var payload = {
                    Id: this.id,
                    Live_description: this.live_description,
                    Live_name: this.live_name,
                    Live_status: parseInt(this.live_status) || 0,
                    Live_url: this.live_url,
                    Option_on: this.option_on,
                    Static_screen: this.static_screen,
                    Static_share_link: this.static_share_link,
                    Static_title: this.static_title,
                    toast: this.$root.$bvToast,
                }
                this.$store.dispatch('home/update_data', payload).then(() => {
                    this.loading = false;
                    this.$router.push({ name: 'home-index' })
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        create_ss_item() {
            this.loading = true;
            var payload = {
                Cost: parseInt(this.ss_cost) || 0,
                Description: this.ss_description,
                Name: this.ss_name,
                Old_cost: parseInt(this.ss_old_cost) || 0,
                Product_code: this.ss_product_code,
                Url: this.ss_url,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('home/create_item', payload).then(() => {
                this.loading = false;
                this.dialog = false;
                this.$store.dispatch('home/get_data');
            }).catch(() => {
                this.loading = false;
            });
        },
        choose(idx) {
            this.option_on = idx;
            this.update_data();
        },
        browse_file() {
            this.$refs.upload.click();
        },
        browse_file_2() {
            this.$refs.upload_2.click();
        },
        async upload_server() {
            // var self = this;
            var files = this.$refs.upload.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.static_screen = url;
                }
            }
        },
        async upload_server_2() {
            // var self = this;
            var files = this.$refs.upload_2.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.ss_url = url;
                }
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },
        delete_images() {
            this.static_screen = "";
        },
        delete_images_2() {
            this.ss_url = "";
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá item: ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('home/delete_item', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.$store.dispatch('home/get_data');
            }).catch(() => {
                this.loading = false;
            });
        },

        
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Home", route: "index" }, { title: "Home" } ]);
        this.$store.dispatch('home/get_data');
    }
};
</script>



